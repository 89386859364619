import { Injectable } from '@angular/core';
import { User } from '../models/user/user.model';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  static getToken(): string {
    return localStorage.getItem('TAClientTk') || '';
  }

  static saveToken(token: string) {
    localStorage.setItem('TAClientTk', token);
  }

  static destroyToken() {
    localStorage.removeItem('TAClientTk');
  }

  static getUser(): any {
    try {
      return JSON.parse(localStorage.getItem('TAClientPrfl') || '');
    } catch (error) {
      return null;
    }
  }

  static saveUser(user: User) {
    localStorage.setItem('TAClientPrfl', JSON.stringify(user));
  }

  static destroyUser() {
    localStorage.removeItem('TAClientPrfl');
  }
}
