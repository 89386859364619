import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { AlertModule } from 'ngx-bootstrap/alert';

/** config Angular i18n */
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

/** config ng-zorro-antd i18n */
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import { NzSpaceModule } from 'ng-zorro-antd/space';

import {
  TuiRootModule,
  TuiDialogModule,
  TuiNotificationsModule,
} from '@taiga-ui/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { ExpirationTokenService } from './core/interceptors/expiration-token.service';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzSpaceModule,
    ToastrModule.forRoot(),
    AlertModule.forRoot(),
    TuiRootModule,
    TuiDialogModule,
    TuiNotificationsModule,
    FontAwesomeModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: es_ES },
    Location,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExpirationTokenService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
