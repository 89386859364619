import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  tap,
} from 'rxjs';
import {
  User,
  CredentialsUser,
  CredentialsBusiness,
} from '../models/user/user.model';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);

  user:any;

  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  token = '';

  constructor(
    private apiService: ApiService,
    private sessionService: SessionService,
    private router:Router,
    private alert:AlertService,
  ) { this.loadToken(); }

  static jwtDecode(token: string): any {
    try {
      return jwt_decode(token);
    } catch (error) {
      return null;
    }
  }

  loadToken() {
    const token = SessionService.getToken();
    if (token) {
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  checkTokenExp(): void {
    const token = SessionService.getToken();
    if (token !== '') {
      const now = Math.trunc(new Date().getTime() / 1000);
      const decodedToken:any = jwt_decode(token);
      if (now > decodedToken.exp) {
        this.purgeAuth();
      }
    } else {
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    // if it is the first time the user logs in
    if (user && !this.getCurrentUser()) {
      this.updateAuthData(user);/// //here
    } else {
      const currentUser = this.getCurrentUser() || undefined;
      const sessionKind = currentUser.sessionKind || undefined;
      // if want to switch from b2b to b2c
      if (sessionKind === 'b2b' && user.sessionKind === 'b2c') {
        // eslint-disable-next-line no-param-reassign
        user.userId = user.id;
        // Save JWT sent from server in localstorage
        this.updateAuthData(user);
      } else {
        this.updateAuthData(user);
      }
    }
  }

  updateAuthData(user: User) {
    // Save user data from server in localstorage
    SessionService.saveUser(user);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticated.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    SessionService.destroyToken();
    SessionService.destroyUser();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticated.next(false);

    this.router.navigate(['/login']);
  }

  getCurrentUser(): User {
    if (!this.currentUserSubject.value.token) {
      const user = SessionService.getUser();
      this.currentUserSubject.next(user);
    }
    return this.currentUserSubject.value;
  }

  attemptAuthB2B(user: User): Observable<User> {
    const businessId = user.businessId ? user.businessId : '';
    return this.getBusinessById(businessId)
      .pipe(
        map((data) => {
          this.setAuth({ ...data, ...user });
          return user;
        }),
      );
  }

  getClaimsB2B(credentials:CredentialsBusiness):Observable<any> {
    return this.apiService.post('/api/auth/login/business', credentials)
      .pipe(
        map((data) => {
          SessionService.saveToken(data.token);
          const decodedToken = UserService.jwtDecode(data.token);
          const response = {
            token: data.token,
            decodedToken,
          };
          return response;
        }),
      );
  }

  attemptAuthB2C(user: User): Observable<User> {
    const taxId = user.taxId ? user.taxId : '';
    return this.getCustomerByTaxId(taxId)
      .pipe(
        map((data) => {
          this.setAuth({ ...user, ...data });
          return user;
        }),
      );
  }

  switchTob2cSession(user: User): Observable<any> {
    const id = user.userId || user.id;
    return this.apiService.post(`/api/auth/switch_to_b2c_session/${id}`)
      .pipe(
        map((data) => {
          SessionService.saveToken(data.token);
          const decodedToken = UserService.jwtDecode(data.token);
          const response = {
            token: data.token,
            decodedToken,
          };
          return response;
        }),
      );
  }

  getClaimsB2C(credentials:CredentialsUser):Observable<any> {
    return this.apiService.post('/api/auth/new_login', credentials)
      .pipe(
        map((data) => {
          SessionService.saveToken(data.token);
          const decodedToken = UserService.jwtDecode(data.token);
          const response = {
            token: data.token,
            decodedToken,
          };
          return response;
        }),
      );
  }

  getClaimsPasswordRecovery(taxId:string):Observable<any> {
    return this.apiService.post('/api/auth/password_recovery_init', { taxId })
      .pipe(
        map((data) => {
          const decodedToken = UserService.jwtDecode(data.token);
          const response = {
            token: data.token,
            decodedToken,
          };
          this.alert.taigaToastSuccess(
            'Recuperación de contraseña',
            'Mail enviado',
          );
          return response;
        }),
      );
  }

  changePasswordEmployee(customerId:string, update:any) {
    return this.apiService.put(`/api/customer/${customerId}/password`, update).pipe(
      tap(() => {
        this.alert.taigaToastSuccess('Se ha modificado exitosamente tu contraseña', 'OK');
      }),
    );
  }

  changePasswordEmployeeFromToken(update:any): Observable<any> {
    return this.apiService.put('/api/auth/password_recovery_reset', update)
      .pipe(
        tap(() => {
          this.alert.taigaToastSuccess(
            'Cambio de contraseña',
            'Se ha modificado exitosamente tu contraseña',
          );
        }),
      );
  }

  changeInfoEmployee(customerId:string, update:any) {
    return this.apiService.put(`/api/customer/${customerId}`, update).pipe(
      tap(() => {
        this.alert.taigaToastSuccess('Se han guardado tus cambios', 'OK');
      }),
    );
  }

  updateEmployee(employeeId:string, update:any) {
    return this.apiService.put(`/api/employee/${employeeId}`, update);
  }

  updateCustomer(customerId:string, update:any) {
    return this.apiService.put(`/api/customer/${customerId}`, update);
  }

  updateBusiness(businessId:string, update:any) {
    return this.apiService.put(`/api/business/${businessId}`, update).pipe(
      tap(() => {
        this.alert.taigaToastSuccess('Se han guardado tus cambios', 'OK');
      }),
    );
  }

  enableCustomerById(id:string, update:any): Observable<User> {
    return this.apiService.put(`/api/employee/${id}/enable`, update).pipe(
      tap(() => {
        this.alert.taigaToastSuccess('Se han guardado tus cambios', 'OK');
      }),
    );
  }

  getCustomerById(id: string): Observable<User> {
    return this.apiService.get(`/api/customer/${id}`);
  }

  getCustomerByTaxId(tax_id: string): Observable<User> {
    return this.apiService.get(`/api/customer/tax_id/${tax_id}`);
  }

  getBusinessById(id: string): Observable<User> {
    return this.apiService.get(`/api/business/${id}`);
  }

  getEmployeeById(id: string): Observable<any> {
    return this.apiService.get(`/api/employee/${id}`);
  }
}
