import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AutoLoginGuard } from './core/guards/auto-login.guard';

const routes: Routes = [
  {
    path: '', // empty path redirects to login
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    // If login path is provided, redirect to AuthModule
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module')
      .then((m) => m.AuthModule),
    canLoad: [AutoLoginGuard],
  },
  {
    // If empty path is provided, redirect to MainModule
    // and perform Guard checks`

    // I need guard safe route
    path: 'main',
    loadChildren: () => import('./pages/main/main.module')
      .then((m) => m.MainModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'sessions',
    loadChildren: () => import('./pages/sessions/sessions.module')
      .then((m) => m.SessionsModule),
  },
  {
    path: '**', // path empty redirect to login
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
