import { Inject, Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TuiAlertService } from '@taiga-ui/core';
import { TuiNotification } from '@taiga-ui/core/enums/notification';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private toastr: ToastrService,
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
  ) { }

  taigaToastWarning(msn: string, label: string) {
    this.notificationsService.open(msn, {
      label,
      status: TuiNotification.Warning,
      autoClose: true,
    }).subscribe();
  }

  taigaToastSuccess(msn: string, label: string) {
    this.notificationsService.open(msn, {
      label,
      status: TuiNotification.Success,
      autoClose: true,
    }).subscribe();
  }

  taigaToastInfo(msn: string, label: string) {
    this.notificationsService.open(msn, {
      label,
      status: TuiNotification.Info,
      autoClose: true,
    }).subscribe();
  }

  taigaToastError(msn: string, label: string) {
    this.notificationsService.open(msn, {
      label,
      status: TuiNotification.Error,
      autoClose: true,
    }).subscribe();
  }

  static errorTimer(text: string) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text,
      timer: 2000,
    });
  }

  static successTimer(text: string) {
    Swal.fire({
      icon: 'success',
      title: 'Exito',
      text,
      timer: 2000,
    });
  }

  static warningTimer(text: string) {
    Swal.fire({
      icon: 'warning',
      title: 'Alerta!',
      text,
      timer: 2000,
    });
  }

  static infoTimer(text: string) {
    Swal.fire({
      icon: 'info',
      text,
      timer: 2000,
    });
  }

  static warningStatic(text: string) {
    Swal.fire({
      title: 'warning',
      text,
    });
  }

  static errorStatic(text: string) {
    Swal.fire({
      title: 'Error',
      text,
    });
  }

  static infoStatic(text: string) {
    Swal.fire({
      title: 'Info',
      text,
    });
  }

  successToast(text: string) {
    this.toastr.show(
      `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
      <div class="alert-text">
        <span class="alert-title" data-notify="title">¡Éxito!</span>
        <span data-notify="message">${text}</span>
      </div>`,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: 'ngx-toastr alert alert-dismissible alert-success alert-notify',
      },
    );
  }

  errorToast(text: string) {
    this.toastr.show(
      `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
      <div class="alert-text">
        <span class="alert-title" data-notify="title">Hubo un error.</span>
        <span data-notify="message">${text}</span>
      </div>`,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: 'ngx-toastr alert alert-dismissible alert-danger alert-notify',
      },
    );
  }

  warningToast(text: string) {
    this.toastr.show(
      `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
      <div class="alert-text">
        <span class="alert-title" data-notify="title">¡Alerta!</span>
        <span data-notify="message">${text}</span>
      </div>`,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: 'ngx-toastr alert alert-dismissible alert-warning alert-notify',
      },
    );
  }
}
